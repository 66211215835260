import requests from '../utils'

async function getUsers() {
  return requests.auth.get(`${window.location.origin}/api/getUsers`) || {}
}

async function editUserById(data, userId) {
  return (
    (await requests.auth.patch(
      `${window.location.origin}/api/editUser?${
        userId
          ? new URLSearchParams({
              userId
            })
          : ''
      }`,
      data
    )) || {}
  )
}

export { getUsers, editUserById }
