function createEnum(keys, values) {
  if (!Array.isArray(keys) || !Array.isArray(values)) return {}
  if (keys.length !== values.length) return {}

  let result = []
  for (let i = 0; i < keys.length; i++) {
    result[(result[values[i]] = keys[i])] = values[i]
  }
  return result
}

export { createEnum }
