import requests from '../utils'

async function getMOD() {
  return (
    requests.auth.get(
      `${window.location.origin}/api/oms/getMOD?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? '',
        omsId: localStorage.getItem('omsId') ?? ''
      })}`
    ) || []
  )
}

export { getMOD }
