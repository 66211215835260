import requests from '../utils'

async function getOrders() {
  return (
    requests.auth.get(
      `${window.location.origin}/api/oms/getOrders?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? '',
        omsId: localStorage.getItem('omsId') ?? ''
      })}`
    ) || []
  )
}

async function getCodesByOrder(data) {
  return (
    requests.auth.get(
      `${window.location.origin}/api/oms/getCodes?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? '',
        omsId: localStorage.getItem('omsId') ?? '',
        ...data
      })}`
    ) || []
  )
}

async function createOrder(data) {
  return (
    requests.auth.post(
      `${window.location.origin}/api/oms/createOrder?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? '',
        omsId: localStorage.getItem('omsId') ?? ''
      })}`,
      data
    ) || {}
  )
}

async function resetOrders() {
  return (
    requests.auth.delete(
      `${window.location.origin}/api/oms/resetOrders?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? ''
      })}`
    ) || {}
  )
}

export { getOrders, getCodesByOrder, createOrder, resetOrders }
