<script>
  import { createEventDispatcher } from 'svelte'
  import { slide } from 'svelte/transition'

  const dispatch = createEventDispatcher()

  export let type = 'error'
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<article
  class="toast {type}"
  on:click={() => dispatch('dismiss')}
  role="alert"
  transition:slide
>
  <slot />
</article>

<style>
  .toast {
    pointer-events: all;
    cursor: pointer;
    width: 40rem;
    padding: 2.5rem 3rem;
    line-height: 1.5;
    font-size: 2.25rem;
    border-radius: 3px;
    outline: 1px solid var(--clr-gray-400);
    background-color: var(--clr-gray-300);

    &.success {
      color: var(--clr-green);
    }

    &.error {
      color: var(--clr-red);
    }

    &.info {
      color: var(--clr-gray-400);
    }
  }
</style>
