<script>
  import { onMount } from 'svelte'
  import { parse } from 'regexparam'
  import Loading from '../components/Loading.svelte'

  onMount(() => {
    onPopState()
    window.addEventListener('popstate', onPopState)
  })

  export let routes

  let params = {},
    routeIndex = -1

  function exec(path, result) {
    let i = 0,
      out = {}
    let matches = result.pattern.exec(path)
    while (i < result.keys.length) {
      out[result.keys[i]] = matches[++i] || null
    }
    return out
  }

  function onPopState() {
    let navigatePathname = `${window.location.pathname}${window.location.search}${window.location.hash}`

    routes.some((route, index) => {
      let parsedRoute = import.meta.env.DEV
        ? parse(route.name)
        : parse(`${import.meta.env.BASE_URL.slice(0, -1)}${route.name}`)
      if (navigatePathname.match(parsedRoute.pattern)) {
        routeIndex = index
        params = exec(navigatePathname, parsedRoute)
        return true
      }
    })
  }
</script>

{#if routeIndex < 0}
  <Loading />
{:else}
  {#await routes[routeIndex].component()}
    <Loading />
  {:then module}
    <svelte:component this={module.default} {...params} />
  {/await}
{/if}
