import requests from '../utils'

async function getCodes(data) {
  return (
    requests.auth.get(
      `${window.location.origin}/api/getCodes?${new URLSearchParams(data)}`
    ) || { codes: [], count: 0 }
  )
}

export { getCodes }
