<script>
  import { productGroupDictionary } from '../dicts'

  export let group
</script>

<span class="icon">
  {@html productGroupDictionary[group].icon}
</span>

<style>
  .icon {
    pointer-events: none;
    position: absolute;
    bottom: 1.8rem;
    z-index: 6;
    left: 1.4rem;
    & svg {
      fill: var(--clr-gray-400);
      color: var(--clr-gray-400);
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  :global(.input:has(.icon:nth-child(2))) {
    position: relative;
  }

  :global(.icon + select) {
    padding-left: 4.3rem;
  }
</style>
