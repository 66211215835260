<script>
  import Navigate from '../router/Navigate.svelte'

  const menu = [
    {
      route: '/',
      title: 'Главная'
    },
    {
      route: '/products',
      title: 'Продукты'
    },
    {
      route: '/orders',
      title: 'Заказы'
    },
    {
      route: '/reports',
      title: 'Отчёты'
    },
    {
      route: '/storage',
      title: 'Склад'
    },
    {
      route: '/lines',
      title: 'Линии'
    },
    {
      route: '/settings',
      title: 'Настройки'
    }
  ]
  menu.find((menuEntry) =>
    window.location.pathname.length > 1
      ? menuEntry.route !== '/' &&
        window.location.pathname.startsWith(menuEntry.route)
      : menuEntry.route === '/'
  ).current = true
</script>

<nav class="menu">
  <ul>
    {#each menu as menuEntry}
      <li class={menuEntry.current ? 'current' : ''}>
        <Navigate to={menuEntry.route}
          ><div>
            {menuEntry.title}
          </div>
        </Navigate>
      </li>
    {/each}
  </ul>
</nav>

<style>
  nav.menu {
    position: sticky;
    top: 7.2rem;
    flex: 0 0 50rem;
    padding-block: 0.9rem;

    & ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 0.9rem;

      & li {
        & div {
          background-color: var(--clr-white);
          font-size: 3.2rem;
          padding: 1.6rem 3.8rem;
          height: 7.2rem;
          color: var(--clr-gray-400);
        }

        &.current div {
          color: var(--clr-black);
        }
      }
    }
  }
</style>
