import requests from '../utils'

async function getReports() {
  return (
    requests.auth.get(
      `${window.location.origin}/api/getReports?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? '',
        omsId: localStorage.getItem('omsId') ?? ''
      })}`
    ) || []
  )
}

async function resetReports() {
  return (
    requests.auth.delete(
      `${window.location.origin}/api/resetReports?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? ''
      })}`
    ) || {}
  )
}

async function createApplicationReport(data) {
  return (
    requests.auth.post(
      `${window.location.origin}/api/oms/createReport?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? '',
        omsId: localStorage.getItem('omsId') ?? ''
      })}`,
      data
    ) || {}
  )
}

async function createCirculationReport(data) {
  return (
    requests.auth.post(
      `${window.location.origin}/api/gismt/createReport?${new URLSearchParams({
        pg: localStorage.getItem('pg') ?? ''
      })}`,
      data
    ) || {}
  )
}

export {
  getReports,
  createApplicationReport,
  createCirculationReport,
  resetReports
}
