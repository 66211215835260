import requests from '../utils'

async function getAuthData() {
  return requests.auth.get(`${window.location.origin}/api/getAuthData`) || {}
}

async function getGISMTToken(signedData) {
  return (
    requests.auth.post(
      `${window.location.origin}/api/gismt/getToken`,
      signedData
    ) || {}
  )
}

async function getOMSToken(signedData) {
  return (
    requests.auth.post(
      `${window.location.origin}/api/oms/getToken`,
      signedData
    ) || {}
  )
}

export { getAuthData, getGISMTToken, getOMSToken }
