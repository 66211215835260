<script>
  import Toast from './Toast.svelte'

  import { dismissToast, toasts } from './store'
</script>

{#if $toasts}
  <section>
    {#each $toasts as toast (toast.id)}
      <Toast type={toast.type} on:dismiss={() => dismissToast(toast.id)}
        >{toast.message}</Toast
      >
    {/each}
  </section>
{/if}

<style>
  section {
    position: fixed;
    inset: 0;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    align-items: flex-end;
    padding: 1rem;
    flex-direction: column;
    gap: 1.25rem;
  }
</style>
