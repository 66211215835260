import requests from '../utils'

async function getMe() {
  return requests.auth.get(`${window.location.origin}/api/getMe`) || {}
}

async function getGISMTBalance() {
  return (
    requests.auth.get(`${window.location.origin}/api/gismt/getBalance`) || {}
  )
}

export { getMe, getGISMTBalance }
