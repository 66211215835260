<script>
  export let user
</script>

<header>
  {#if !user}
    <div>
      <svg
        style="width: 6rem; height: 6rem"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.9876 39.6022C33.9876 39.6022 36.137 39.6566 37.7955 38.5915C39.4539 37.5157 41.3633 34.288 41.3633 34.288L41.396 18.2801C41.4287 18.3019 41.6906 18.4432 43.7527 19.5516C46.2949 20.9101 46.2949 22.9641 46.2949 22.9641C46.2949 22.9641 46.2949 34.2554 46.2949 36.7875C46.2949 39.3197 42.869 41.265 42.869 41.265C42.869 41.265 35.1441 45.6446 32.3073 47.1117C29.4705 48.5679 30.3543 55.0016 30.3543 55.0016C30.3543 55.0016 45.3239 46.3292 48.6517 44.4057C51.9685 42.4821 51.8594 38.3416 51.8594 38.3416C51.8594 38.3416 51.8594 25.9743 51.8594 21.3991C51.8594 16.8239 47.7461 15.2589 47.7461 15.2589L33.7257 7.13004L33.6821 28.0826L26.5465 20.3993C26.5465 20.3993 24.397 20.345 22.7386 21.41C21.0802 22.4859 19.1708 25.7135 19.1708 25.7135L19.138 41.7214C19.1053 41.6997 18.8435 41.5584 16.7813 40.4499C14.2391 39.0915 14.2391 37.0375 14.2391 37.0375C14.2391 37.0375 14.2391 25.7461 14.2391 23.214C14.2391 20.6819 17.6651 18.7366 17.6651 18.7366C17.6651 18.7366 25.3899 14.357 28.2267 12.8899C31.0526 11.4336 30.1688 5 30.1688 5C30.1688 5 15.1884 13.6723 11.8715 15.5958C8.55461 17.5194 8.66372 21.6599 8.66372 21.6599C8.66372 21.6599 8.66372 34.0272 8.66372 38.6024C8.66372 43.1777 12.7771 44.7426 12.7771 44.7426L26.7974 52.8606L26.841 31.908L33.9876 39.6022Z"
          fill="white"
        />
      </svg>
      <p>Nova Module. Администрирование</p>
    </div>
  {:else}
    <a href="/">
      <svg
        style="width: 6rem; height: 6rem"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M33.9876 39.6022C33.9876 39.6022 36.137 39.6566 37.7955 38.5915C39.4539 37.5157 41.3633 34.288 41.3633 34.288L41.396 18.2801C41.4287 18.3019 41.6906 18.4432 43.7527 19.5516C46.2949 20.9101 46.2949 22.9641 46.2949 22.9641C46.2949 22.9641 46.2949 34.2554 46.2949 36.7875C46.2949 39.3197 42.869 41.265 42.869 41.265C42.869 41.265 35.1441 45.6446 32.3073 47.1117C29.4705 48.5679 30.3543 55.0016 30.3543 55.0016C30.3543 55.0016 45.3239 46.3292 48.6517 44.4057C51.9685 42.4821 51.8594 38.3416 51.8594 38.3416C51.8594 38.3416 51.8594 25.9743 51.8594 21.3991C51.8594 16.8239 47.7461 15.2589 47.7461 15.2589L33.7257 7.13004L33.6821 28.0826L26.5465 20.3993C26.5465 20.3993 24.397 20.345 22.7386 21.41C21.0802 22.4859 19.1708 25.7135 19.1708 25.7135L19.138 41.7214C19.1053 41.6997 18.8435 41.5584 16.7813 40.4499C14.2391 39.0915 14.2391 37.0375 14.2391 37.0375C14.2391 37.0375 14.2391 25.7461 14.2391 23.214C14.2391 20.6819 17.6651 18.7366 17.6651 18.7366C17.6651 18.7366 25.3899 14.357 28.2267 12.8899C31.0526 11.4336 30.1688 5 30.1688 5C30.1688 5 15.1884 13.6723 11.8715 15.5958C8.55461 17.5194 8.66372 21.6599 8.66372 21.6599C8.66372 21.6599 8.66372 34.0272 8.66372 38.6024C8.66372 43.1777 12.7771 44.7426 12.7771 44.7426L26.7974 52.8606L26.841 31.908L33.9876 39.6022Z"
          fill="white"
        />
      </svg>
      <p>Nova Module. Администрирование</p>
    </a>
    {#if !('error' in user || !('createdAt' in user))}
      <div>
        {#if user.role === 'admin' && window.location.pathname.startsWith('/admin')}
          <p>Админ Панель</p>
        {:else if user.role === 'admin'}
          <a href="/admin">
            <p>
              {user.companyName}
            </p>
          </a>
          <p style="margin-left: 4rem;">
            <a
              href="/logout"
              on:click={() => {
                localStorage.removeItem('gismtToken')
                localStorage.removeItem('omsToken')
                localStorage.removeItem('deviceId')
              }}>Выйти</a
            >
          </p>
          <style>
            body > header > div:nth-child(2) {
              display: flex;
              align-items: center;
              & a {
                color: var(--clr-white);
                text-decoration: none;
              }
            }
          </style>
        {:else}
          <p>
            {user.companyName}
          </p>
        {/if}
      </div>
    {/if}
  {/if}
</header>

<style>
  header {
    height: 8rem;
    background-color: var(--clr-accent);
    color: var(--clr-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 3.2rem;
    padding: 0 3rem;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;

    & div:first-child,
    & a:first-child {
      gap: 1rem;
      display: flex;
      align-items: center;

      & p {
        white-space: nowrap;
      }
    }
  }
</style>
