import { writable } from 'svelte/store'

export const toasts = writable([])

export const dismissToast = (id) => {
  toasts.update((all) => all.filter((t) => t.id !== id))
}

const random = () => crypto.getRandomValues(new Uint8ClampedArray(1))[0] / 255

function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const addToast = (toast) => {
  const id = uuid()

  const defaults = {
    id,
    type: 'info',
    dismissible: true,
    timeout: 3000
  }

  const t = { ...defaults, ...toast }
  toasts.update((all) => {
    const toasts = [t, ...all]
    toasts.length = Math.min(toasts.length, 11)
    return toasts
  })

  if (t.timeout) setTimeout(() => dismissToast(id), t.timeout)
}
